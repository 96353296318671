<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="200px"
          label-position="right"
          size="mini"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="区">
                <el-input v-model="form.districtName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="街道">
                <el-input v-model="form.streetName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="小区">
                <el-input v-model="form.communityName" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="问卷名称">
                <el-input v-model="form.surveyName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="题目">
                <el-input v-model="form.questionTitle" disabled></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="8">
              <el-form-item label="巡检得分">
                <el-input v-model="form.patrolScore" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
           <el-row>
            <el-col :span="8">
              <el-form-item label="巡检人">
                <el-input v-model="form.patrolCreateByName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="巡检时间">
                <el-input v-model="form.patrolTime" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="巡检描述">
              <el-input type="textarea" :rows="5" v-model="form.patrolRemark" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item label="巡检图片">
              <div class="demo-image">
                    <div
                      class="block"
                      v-for="imgUrl in form.patrolImageUrlList"
                      :key="imgUrl"
                    >
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="imgUrl"
                        :preview-src-list="form.patrolImageUrlList"
                        :fit="'cover'"
                      ></el-image>
                    </div>
                  </div>
            </el-form-item>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="复议人">
                <el-input v-model="form.createByName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="复议时间">
                <el-input v-model="form.createTime" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
           <el-row>
            <el-col :span="24">
              <el-form-item label="复议描述">
              <el-input type="textarea" :rows="5" v-model="form.reason" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
           <el-row>
             <el-col :span="8">
               <el-form-item label="得分">
                 <el-input type="number" :max="4" :min="1" :step="1" v-model="submitForm.score"></el-input>
               </el-form-item>
             </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="回复意见">
              <el-input type="textarea" :rows="5" v-model="submitForm.remark"></el-input>
              </el-form-item>

            </el-col>
          </el-row>
        
          <el-form-item style="overflow:hidden" >
            <el-button type="primary" @click="onSubmit" style="float: right;">提交</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-container>
</template>
<style>
.text {
  font-size: 14px;
}

.demo-image {
  overflow: hidden;
}
.demo-image > div {
  float: left;
  margin: 5px 5px 0 0;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  overflow: hidden;
}
</style>
<script>
export default {
  data() {
    return {
      form: {},
      userInfo: {},
      rules: {},
      submitForm:{},
    };
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getReconsiderDetail(self.$route.query.guid || null);
  },
  methods: {
    getReconsiderDetail(guid) {
      let self = this;
      if (guid) {
        self.$axios
          .get("/reconsider-history/get/" + guid, {
            headers: { token: self.userInfo.token },
          })
          .then(function (response) {
            self.form = response.data.data;
          })
          .catch(function (error) {
            self.$message({
              message: error.response.data.message,
              type: "warning",
            });
          });
      } else {
        self.form = {
          optionList: [],
          attachOptionRuleArray: [],
        };
      }
    },
    onSubmit() {
      let self = this;
      self.submitForm.guid = self.form.guid;
      if(self.submitForm.score>4 || self.submitForm.score<1){
            self.$message({
                message: "得分请填写[1-4]范围内的整数",
                type: "warning",
            });
              return false;
      }
      self.$refs["form"].validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(self.submitForm));
          console.log(data)
          // self.$axios
          //   .post("/reconsider-history/handle", JSON.stringify(data), {
          //     headers: {
          //       "content-type": "application/json",
          //       token: self.userInfo.token,
          //     },
          //   })
          //   .then(function () {
          //     self.$message({
          //       message: "操作成功",
          //       type: "success",
          //     });
          //     self.$router.push({ name: "patrol_survey_reconsider_list" });
          //   })
          //   .catch(function (error) {
          //     self.$message({
          //       message: error.response.data.message,
          //       type: "warning",
          //     });
          //   });
        } else {
          self.$message({
            message: "请输入正确的选项信息",
            type: "warning",
          });
          return false;
        }
      });
    },
  },
};
</script>